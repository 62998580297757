const CONSTANTS = {
    hostURL : "https://dashboard.daidish.com/",
    // hostURL : "https://dashboard-qa-dot-daidish278606.el.r.appspot.com/",
    firebaseConfig: {
        apiKey: "AIzaSyBAuYM9Rd1Y3qrpQRODV2hEAF6q3I4E-YU",
        authDomain: "daidish-fbase.firebaseapp.com",
        projectId: "daidish-fbase",
        storageBucket: "daidish-fbase.appspot.com",
        messagingSenderId: "619914981182",
        appId: "1:619914981182:web:1a9928335ccbfc446b066f",
        measurementId: "G-9LSM50W79S"
    }
}

export default CONSTANTS;

import firebase from 'firebase/app';
import 'firebase/messaging';
import CONSTANTS from './config/constants';

firebase.initializeApp(CONSTANTS.firebaseConfig);
var messaging;
if (firebase.messaging.isSupported()){
    messaging = firebase.messaging();
}

export default messaging;